import request from "@/utils/request";

// 访客申请
export function saveVisitorInfo(data: any) {
  return request({
    url: "/project/projectVisitorInfo/saveVisitorInfo",
    method: "post",
    data: data,
  });
}

//受访人申请
export function getIntervieweeList(data: any) {
  return request({
    url: "/center/sys/user/pageUserLeaderCheck",
    method: "get",
    params: data,
  });
}

//获取审批访客列表
export function getUserNeedApproveInfo(data: any) {
  return request({
    url: "/project/projectVisitorInfo/getUserNeedApproveInfo",
    method: "get",
    params: data,
  });
}

// 访客审批
export function approveInfo(data: any) {
  return request({
    url: "/project/projectVisitorInfo/approveInfo",
    method: "post",
    data: data,
  });
}