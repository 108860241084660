
import { Options, Vue, Watch } from "vue-property-decorator";
import * as api from "@/api/visitor.ts";
import File from "@/api/file/index.ts";

export default class extends Vue {
  title = "访客登记";
  canelTitle = "下一页";
  private currentDate = new Date();
  private visitUserSelect = false;
  private areaIdscolumns: any[] = [];
  private minDate = new Date(1949, 10, 1);
  private maxDate = new Date(2025, 10, 1);
  private resultname = "";
  private uploader: any[] = [];
  private resultEndPlanPicker = false;
  private resultStartPlanPicker = false;
  private flag = false;
  private visitUserSelectInfo = null;
  protected goBack() {
    this.$router.go(-1);
  }
  private IntervieweeList: any[] = [];

  private IntervieweeListField: any = {
      text: 'name',
      value: 'userId'
  }

  private submitform: any = {
    projectId:null,
    name: "",
    age: "",
    phone: "",
    sex: 0,
    faceFileId: null,
    faceUrl: null,
    startPlan: "",
    endPlan: "",
    reason:null,
    visitUserName:""
  };

  private getInterviewee: any = {
    projectId:null,
    name:"",
    pageNum:1,
    pageSize:20
  };
  
  private changePersonName = "";

  @Watch("uploader", { deep: true })
  watchFile(files: any) {
    let obj = files.find((file: any) => {
      return file.status === "uploading";
    });
    if (!obj) {
      this.flag = false;
    } else {
      this.flag = true;
    }
  }

  mounted(){
    this.getIntervieweeList();
  }

  confirmInterviewee(val: any) {
    this.visitUserSelectInfo = val;
    this.submitform.visitUserName = val.name;
    this.submitform.visitUserId = val.userId;
    this.visitUserSelect = false;
  }

  selectInterviewee(){
    this.visitUserSelect = false;
    this.getInterviewee.name = this.submitform.visitUserName;
    this.getInterviewee.projectId = this.$store.state.project.projectinfo.projectId;
    api.getIntervieweeList({
      ...this.getInterviewee
    }).then((res: any) => {
        if (res.code === 0) {
          this.IntervieweeList = res.data.records
          this.visitUserSelect = true;
        } else {
          this.$toast.fail(res.msg);
        }
      });
  }

  getIntervieweeList(){
    this.getInterviewee.projectId = this.$store.state.project.projectinfo.projectId;
    api.getIntervieweeList({
      ...this.getInterviewee
    }).then((res: any) => {
        if (res.code === 0) {
          this.IntervieweeList = res.data.records
        } else {
          this.$toast.fail(res.msg);
        }
      });
  }

  saveVisitor() {
    api.saveVisitorInfo({
        ...this.submitform
      })
      .then((res: any) => {
        if (res.code === 0) {
          this.$toast.success(res.msg);
          this.$router.go(-1);
        } else {
          this.$toast.fail(res.msg);
        }
      });
  }

  protected onSubmit() {
    this.submitform.projectId = this.$store.state.project.projectinfo.projectId;
    this.saveVisitor();
  }

  protected confirmStartPlanPicker(val: { getFullYear: () => any; getMonth: () => number; getDate: () => any; getHours: () => any; getMinutes: () => any; }) {
      let year = val.getFullYear()//年
      let month = val.getMonth() + 1//月
      let day = val.getDate()//日
      let hour = val.getHours()//时
      let minute = val.getMinutes()//分
      let months = '';
      let days = '';
      let hours = '';
      let minutes = '';
      if (month >= 1 && month <= 9) {months = "0" + month}else{months = "" + month};
      if (day >= 1 && day <= 9) { days = `0${day}` }else{days = "" + day}
      if (hour >= 0 && hour <= 9) { hours = `0${hour}` }else{hours = "" + hour}
      if (minute >= 0 && minute <= 9) { minutes = `0${minute}` }else{minutes = "" + minute}
      this.submitform.startPlan = `${year}-${months}-${days} ${hours}:${minutes}:00`
      this.resultStartPlanPicker = false
    }

  protected confirmEndPlanPicker(val: { getFullYear: () => any; getMonth: () => number; getDate: () => any; getHours: () => any; getMinutes: () => any; }) {
      let year = val.getFullYear()//年
      let month = val.getMonth() + 1//月
      let day = val.getDate()//日
      let hour = val.getHours()//时
      let minute = val.getMinutes()//分
      let months = '';
      let days = '';
      let hours = '';
      let minutes = '';
      if (month >= 1 && month <= 9) {months = "0" + month}else{months = "" + month};
      if (day >= 1 && day <= 9) { days = `0${day}` }else{days = "" + day}
      if (hour >= 0 && hour <= 9) { hours = `0${hour}` }else{hours = "" + hour}
      if (minute >= 0 && minute <= 9) { minutes = `0${minute}` }else{minutes = "" + minute}
      this.submitform.endPlan = `${year}-${months}-${days} ${hours}:${minutes}:00`
      this.resultEndPlanPicker = false
    }

  afterRead(files: any) {
    let filearr = null;
    if (!Array.isArray(files)) {
      filearr = [files];
    } else {
      filearr = [...files];
    }

    filearr.map((file: any) => {
      file.status = "uploading";
      file.message = "上传中...";
      File.uploadFile(
        "inspectionmobile/",
        file.file.name,
        file.file,
        true
      ).then((res: any) => {
        file.status = "done";
        this.submitform.faceFileId = res.data.id;
        this.submitform.faceUrl = res.data.url;
      });
    });
  }
}
